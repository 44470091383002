import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    console.log('no',to.path)
    next();
    return;
  }
}

// const ifManager = (to, from, next) => {
  
//   if (store.getters.isAuthenticated) {
//     const pages = store.getters.getAuthUserPages;
    
//     if(pages && pages.length > 0){
//       // const urls = pages.map(page => page.url);
//       const urls = pages

//       const allowedPaths = ['/orders/edit/', '/orders/copy/', '/goods/history/'];

//       // Check if the requested path is in the allowed URLs or matches any allowed path substring
//       const hasAccess = urls.includes(to.path) || allowedPaths.some(path => to.path.includes(path));

//       if (hasAccess) {
//         console.log("Access granted to:", to.path);
//         next(); // Allow access to the requested route
//       } else {
//         console.log("Access denied to:", to.path);
//         next(urls[0]); // Redirect to the first allowed route from the URLs
//       }
//     }
//   } else {
//     next('/login'); // Redirect to login if not authenticated
//   }
// };

const ifManager = (to, from, next) => {
  // Retrieve the token from localStorage
  const token = localStorage.getItem('user-token'); // Replace 'token' with your actual token key

  // Check if the token exists to determine if the user is authenticated
  const isAuthenticated = token !== null && token !== '';

  if (isAuthenticated) {
    const pages = JSON.parse(localStorage.getItem('pages'));
    if (pages && pages.length > 0) {
      const urls = pages;
      const allowedPaths = ['/orders/edit/', '/orders/copy/', '/goods/history/'];
      const hasAccess = urls.includes(to.path) || allowedPaths.some(path => to.path.startsWith(path));

      if (hasAccess) {
        console.log("Access granted to:", to.path);
        next(); // Allow access to the requested route
      } else {
        console.log("Access denied to:", to.path);
        next(urls[0]); // Redirect to an unauthorized page or a default page
      }
    } else {
      next('/unauthorized'); // Redirect if no pages are available
    }
  } else {
    next('/login'); // Redirect to login if not authenticated
  }
};


Vue.use(Router);

const routes= [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Home.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/login/',
    name: 'login',
    component: () => import('./views/Login.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/clients/',
    name: 'clients',
    component: () => import('./views/Clients/List.vue'),
    beforeEnter: ifManager,
  },
  // {
  //   path: '/florists/',
  //   name: 'florists',
  //   component: () => import('./views/Florists/List.vue'),
  //   beforeEnter: ifManager,
  // },
  // {
  //   path: '/couriers/',
  //   name: 'couriers',
  //   component: () => import('./views/Couriers/List.vue'),
  //   beforeEnter: ifManager,
  // },
  {
    path: '/users/',
    name: 'users',
    component: () => import('./views/Users/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/users-groups/',
    name: 'users-groups',
    component: () => import('./views/UsersGroups/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/bouquets/',
    name: 'bouquets',
    component: () => import('./views/Bouquets/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/bouquets/qr/',
    name: 'bouquetsQr',
    component: () => import('./views/QrBouquets/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/bouquets/web/',
    name: 'bouquetsWeb',
    component: () => import('./views/WebBouquets/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/payment-types/',
    name: 'payment-types',
    component: () => import('./views/PaymentTypes/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/pays/',
    name: 'pays',
    component: () => import('./views/Pays/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/orders/',
    name: 'orders',
    component: () => import('./views/Orders/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/orders/add/',
    name: 'ordersAdd',
    component: () => import('./views/Orders/add.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/orders/edit/:id/',
    name: 'ordersEdit',
    component: () => import('./views/Orders/edit.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/orders/copy/:id/',
    name: 'ordersCopy',
    component: () => import('./views/Orders/copy.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/orders/settings/',
    name: 'settings',
    component: () => import('./views/Orders/userSettings.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/orders/',
    name: 'ordersList',
    component: () => import('./views/Orders/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/deliveries/',
    name: 'deliveries',
    component: () => import('./views/Deliveries/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/goods/',
    name: 'goods',
    component: () => import('./views/Goods/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/goods/history/',
    name: 'history',
    component: () => import('./views/Goods/History.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/goods/history/:id/',
    name: 'historyView',
    component: () => import('./views/Goods/HistoryView.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/setting-remote/',
    name: 'settingRemote',
    component: () => import('./views/SettingRemote/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/vt-payment/',
    name: 'VtPayment',
    component: () => import('./views/Vt/add.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/qr/',
    name: 'qrbank',
    component: () => import('./views/Qr/List.vue'),
    beforeEnter: ifManager,
  },
  // {
  //   path: '/page-setting/',
  //   name: 'PageSetting',
  //   component: () => import('./views/Page/List.vue'),
  //   // beforeEnter: ifManager,
  // },
  // {
  //   path: '/print/order/delivery/:ids/',
  //   name: 'orderDelivery',
  //   component: () => import('./views/Print/ordersDelivery.vue'),
  //   // beforeEnter: ifAuthenticated,
  //   meta: {
  //     pagePrint: true,
  //   },
  // },
  {
    path: '/print/orders/delivery/:ids/',
    name: 'ordersDelivery',
    component: () => import('./views/Print/ordersDelivery.vue'),
    // beforeEnter: ifAuthenticated,
    meta: {
      pagePrint: true,
    },
  },
  {
    path: '/vt',
    name: 'qrVT',
    component: () => import('./views/Vt/qrVT.vue'), // Adjust the path to your component
  },
  {
    path: '/bank/success/',
    name: 'success',
    component: () => import('./views/Bank/success.vue'), // Adjust the path to your component
  },
  {
    path: '/bank/fail',
    name: 'fail',
    component: () => import('./views/Bank/fail.vue'), // Adjust the path to your component
  },
  // {
  //   path: '/print/order/florist/:id/',
  //   name: 'orderFlorist',
  //   component: () => import('./views/Print/orderFlorist.vue'),
  //   // beforeEnter: ifAuthenticated,
  //   meta: {
  //     pagePrint: true,
  //   },
  // },
  {
    path: '/print/orders/florist/:ids/',
    name: 'ordersFlorist',
    component: () => import('./views/Print/ordersFlorist.vue'),
    // beforeEnter: ifAuthenticated,
    meta: {
      pagePrint: true,
    },
  },
  {
    path: '/print/bouquet/receipt/:id/',
    name: 'bouquet​Receipt',
    component: () => import('./views/Print/bouquet​Receipt.vue'),
    // beforeEnter: ifAuthenticated,
    meta: {
      pagePrint: true,
    },
  },
  {
    path: '/print/order/day-orders/:date/',
    name: 'ordersOfDay',
    component: () => import('./views/Print/orderDeliveryDay.vue'),
    // beforeEnter: ifAuthenticated,
    meta: {
      pagePrint: true,
    },
  },
  {
    path: '/stores/',
    name: 'stores',
    component: () => import('./views/Stores/List.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/reports/',
    name: 'repprts',
    component: () => import('./views/Reports/created.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/reports/graphQL/',
    name: 'repprtsGrapgQL',
    component: () => import('./views/Reports/graphQL.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/reports/goods/',
    name: 'repprtsGoods',
    component: () => import('./views/Reports/goods.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/reports/closeDay/',
    name: 'repprtsCloseDay',
    component: () => import('./views/Reports/CloseDay/closeDay.vue'),
    beforeEnter: ifManager,
  },
  {
    path: '/reviews/',
    name: 'reviews',
    component: () => import('./views/Reviews/List.vue'),
    beforeEnter: ifManager,
  },
]
export default new Router({
  mode: 'history',
  routes
});
